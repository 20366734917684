import API from "../../Data/Domain/API";
import URL from "../../Data/Domain/URL";

export async function getStationData(params) {
  const url = URL.setParams(URL.buildUrl("stationdata"), params);
  console.log("URL: ", url);

  try {
    const response = await new API(url).get();
    console.log("Response: ", response);
    return response;
  } catch (error) {
    console.error("Error fetching station data:", error);
    throw error;
  }
}