import { createSlice } from "@reduxjs/toolkit";
import { getStationData } from "../Application/StationData.business";

export const slice = createSlice({
  name: "stationData",
  initialState: {
    list: [],
    count: 0,
  },
  reducers: {
    setStationData: (state, { payload: list }) => {
      return {
        ...state,
        list,
      };
    },
    countStationData: (state, { payload: count }) => {
      return {
        ...state,
        count,
      };
    },
  },
});

export const { setStationData, countStationData } = slice.actions;

export default slice.reducer;

export const getStationDataSelector = (store) => {
  const stationData = store.stationData;
  return stationData?.list || [];
};
export const getStationDataCountSelector = (store) => store.stationData.count;


export const fetchStationData = (params) => async (dispatch) => {
  try {
    console.log("Fetching station data with params:", params);
    const response = await getStationData(params);
    dispatch(setStationData(response.data.resources));
    dispatch(countStationData(response.data.count));
  } catch (error) {
    console.error("Error fetching station data:", error);
  }
};